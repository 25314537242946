<!--访客模块--访客详情窗口-->
<template>
  <div id="visitorDetailsModel" v-loading="loading" :element-loading-text="$t('alarm.a0')">
    <div class="main"> 
        <a-descriptions :title="$t('visitorDetailsModel.a1')" style="border-bottom:1px solid #d5d5d5;">
          <a-descriptions-item :label="$t('visitorDetailsModel.a6')">
            {{visitorDetails.orderNo}}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('visitorDetailsModel.a9')">
            {{visitorDetails.bookStartTime}}~{{visitorDetails.bookEndTime}} 
          </a-descriptions-item>
          <a-descriptions-item :label="$t('visitorDetailsModel.a10')">
            {{visitorDetails.dateTime}} 
          </a-descriptions-item>
          <a-descriptions-item :label="$t('visitorDetailsModel.a7')">
            {{visitorDetails.orderStatusName}}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('visitorDetailsModel.a8')">
            {{visitorDetails.visitorStatusName}}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('visitorDetailsModel.a11')">
            {{visitorDetails.visitorTypeName}}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('visitorDetailsModel.a12')">
            {{visitorDetails.visitorReasonText}}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('visitorDetailsModel.a13')"  span="2">
            {{visitorDetails.visitorRemark}}
          </a-descriptions-item>
        
          <a-descriptions-item :label="$t('visitorDetailsModel.a14')" span="2">
              
              <a-textarea v-model="visitorDetails.memo"  :auto-size="{ minRows: 3, maxRows: 5 }" style="width:400px;">
              </a-textarea>
              &nbsp;
              <a-button type="primary"  @click="memo()" style="margin-left:5px;" :disabled="!(visitorDetails.orderStatus==1|| visitorDetails.orderStatus==2)">
                    确认
              </a-button> 
          </a-descriptions-item>
      </a-descriptions>
      <a-descriptions :title="$t('visitorDetailsModel.a2')" style="border-bottom:1px solid #d5d5d5;" :column="4">
          <a-descriptions-item :label="$t('visitorDetailsModel.a15')">
          {{visitorDetails.managementCode}}
          </a-descriptions-item> 
          <a-descriptions-item :label="$t('visitorDetailsModel.a16')">
          {{visitorDetails.name}} 
          </a-descriptions-item> 
          <a-descriptions-item :label="$t('visitorDetailsModel.a17')">
          {{visitorDetails.gr1}}
          </a-descriptions-item> 
          <a-descriptions-item :label="$t('visitorDetailsModel.a18')">
          {{visitorDetails.mobilePhone}}
          </a-descriptions-item> 

      </a-descriptions>
      <a-descriptions :title="$t('visitorDetailsModel.a3')" style="border-bottom:1px solid #d5d5d5;" :column="4">
        
          <a-descriptions-item :label="$t('visitorDetailsModel.a16')">
          {{visitorDetails.visitorName}} 
          </a-descriptions-item> 
          <a-descriptions-item :label="$t('visitorDetailsModel.a17')">
          {{visitorDetails.visitorCompany}}
          </a-descriptions-item> 
          <a-descriptions-item :label="$t('visitorDetailsModel.a18')">
          {{visitorDetails.visitorPhone}}
          </a-descriptions-item> 
          <a-descriptions-item :label="$t('visitorDetailsModel.a19')">
          {{visitorDetails.certificateTypeName}}
          </a-descriptions-item> 
          <a-descriptions-item label="车牌号码">
          {{visitorDetails.visitorCarNo}}
          </a-descriptions-item> 
      </a-descriptions>
    

      <div class="ant-descriptions-title">{{$t('visitorDetailsModel.a4')}}</div>
      <div style="border-bottom:1px solid #d5d5d5;padding:5px;margin:5px;">        
        <a-table :columns="columnsVisitor" :data-source="visitorDetails.visitors" bordered :row-key="record => record.id" >
          <template slot="visitor" slot-scope="text, record">
              
              <!-- <p>姓名：{{record.visitorName}} 访客类型：{{record.visitorTypeName}}</p> -->
              <a-descriptions :column="2" style="border-bottom:1px solid #d5d5d5;">
                  <a-descriptions-item :label="$t('visitorDetailsModel.a16')">
                  {{record.visitorName}} 
                  </a-descriptions-item> 
                  <a-descriptions-item :label="$t('visitorDetailsModel.a11')">
                  {{record.visitorTypeName}}
                  </a-descriptions-item> 
              </a-descriptions>
              <!-- <p>联系方式：{{record.visitorPhone}} 所属公司/单位：{{record.visitorCompany}}</p> -->
              <a-descriptions :column="2" style="border-bottom:1px solid #d5d5d5;">
                  <a-descriptions-item :label="$t('visitorDetailsModel.a18')">
                  {{record.visitorPhone}} 
                  </a-descriptions-item> 
                  <a-descriptions-item :label="$t('visitorDetailsModel.a17')">
                  {{record.visitorCompany}}
                  </a-descriptions-item> 
              </a-descriptions>

              <!-- <p>证件类型：{{record.certificateTypeName}} 证件号码：{{record.certificateNo}}</p> -->
              <a-descriptions :column="2">
                  <a-descriptions-item :label="$t('visitorDetailsModel.a19')">
                  {{record.certificateTypeName}} 
                  </a-descriptions-item> 
                  <a-descriptions-item :label="$t('visitorDetailsModel.a20')">
                  {{record.certificateNo}}
                  </a-descriptions-item> 
              </a-descriptions>

            <!-- <span>
              <a-divider/>
              <a-divider/>
            </span> -->
          </template>
          <template slot="certificate" slot-scope="text, record">
              <!-- <p>访客码：{{record.visitorNo}}</p> -->
              <a-descriptions :column="1" style="border-bottom:1px solid #d5d5d5;">
                  <a-descriptions-item :label="$t('visitorDetailsModel.a31')">
                  {{record.visitorNo}} 
                  </a-descriptions-item> 
              </a-descriptions>
            <!-- <p>认证方式：{{record.passTypeName}}</p> -->
              <a-descriptions :column="1" style="border-bottom:1px solid #d5d5d5;">
                  <a-descriptions-item :label="$t('visitorDetailsModel.a32')">
                  {{record.passTypeName}} 
                  </a-descriptions-item> 
              </a-descriptions>
              <a-descriptions :column="1">
            <!-- <p>拜访状态：{{record.visitorStatusName}}</p> -->
                  <a-descriptions-item :label="$t('visitorDetailsModel.a8')">
                  {{record.visitorStatusName}} 
                  </a-descriptions-item> 
              </a-descriptions>
          </template>
          <template slot="accessRole" slot-scope="text, record">
            <div v-for="(item,index) in record.peopleAccessRole" :key="index" >    
                <!-- <p>通行模式：{{item.accessRoleName}}</p> -->
                <div style="border:1px solid #d5d5d5;border-radius: 3px;padding:3px;margin:3px;">
                  <div style="border-bottom:1px solid #d5d5d5;padding:2px;margin:2px;display:flex;justify-content: space-between;">
                    <span>{{$t('visitorDetailsModel.a33')}}:</span>
                    <span>{{item.accessRoleName}}</span>
                    
                  </div>
                   <div style="padding:2px;margin:2px;">
                    {{$t('visitorDetailsModel.a34')}}:
                     <div>{{item.activationTime}}~{{item.expirationTerm}}</div>
                    
                  </div>
                </div>
               <!-- <a-descriptions :column="1" style="border-bottom:1px solid #d5d5d5;">
                  <a-descriptions-item :label="$t('visitorDetailsModel.a33')"  :style="{'padding-bottom':'4px'}">
                  {{item.accessRoleName}} 
                  </a-descriptions-item> 
              </a-descriptions> -->
                <!-- <p>通行时间：{{item.activationTime}}~{{item.expirationTerm}}</p> -->
              <!-- <a-descriptions :column="1" layout="vertical">
                  <a-descriptions-item :label="$t('visitorDetailsModel.a34')" :style="{'padding-bottom':'4px'}">
                  {{item.activationTime}}~{{item.expirationTerm}}
                  </a-descriptions-item> 
              </a-descriptions> -->
            </div>
          </template>
          <template slot="operate" slot-scope="text, record">
            <div v-if="record.visitorStatus!=4">
              <a-button type="primary"  @click="confirm(record)" v-if="record.visitorStatus==2" >
                离开确认
              </a-button>
              <!-- :disabled="!(visitorDetails.orderStatus==1 || visitorDetails.orderStatus==2)" -->
               <a-button type="primary"  @click="confirm(record)" v-else-if="record.visitorStatus==3" >
                完成确认
              </a-button>
               <a-button type="primary"  @click="confirm(record)" v-else :disabled="!(visitorDetails.orderStatus==1|| visitorDetails.orderStatus==2)">
                到访确认
              </a-button>
            </div>
            <!-- 
              size="small"
              <a-button type="danger" size="small" @click="confirm(record)">
              离开确认
            </a-button> -->
          </template>
        </a-table>
      </div>
      <div class="ant-descriptions-title">{{$t('visitorDetailsModel.a5')}}</div>
      <div style="border-bottom:1px solid #d5d5d5;padding:5px;margin:5px;">
        <!-- {{$t('visitorDetailsModel.a5')}} -->
      <!-- <a-divider orientation="left">{{$t('visitorDetailsModel.a5')}}</a-divider>:scroll="size" -->
         <a-table :columns="columnsProcess" :dataSource="visitorDetails.events"  :row-key="record => record.id" > </a-table>
      </div>
    </div>       
  </div>
</template>

<script>

import {confirmVisitorStatus,updateMemo,getVisitorOrderInfo} from "../../api/visitor";

export default { 
  props: {
    visitorDetails:{},
  }, 
  data() {
    return {   
      loading:false,
      size: {  x: 1200,y: 240 },
      labelCol: { span:4 },
      wrapperCol: { span: 20 },        
      columnsVisitor:[
        {
          title: "NO",
          dataIndex: "no",
          width: 50,
        },
        {
          title: this.$t("visitorDetailsModel.a21"),
          ellipsis: true,
          width: 360,
          scopedSlots: { customRender: 'visitor' },
        },
        {
          title: this.$t("visitorDetailsModel.a22"),
          scopedSlots: { customRender: 'certificate' },
          width: 160,
          ellipsis: true,
        },
        {
          title: this.$t("visitorDetailsModel.a23"),
          scopedSlots: { customRender: 'accessRole' },
          width: 240,
          ellipsis: true,
        },
        {
          title: this.$t("visitorDetailsModel.a24"),
          scopedSlots: { customRender: 'operate' },
          width: 80,
          ellipsis: true,
        },
      ],
      columnsProcess: [
        {
          title: "NO",
          dataIndex: "no",
          width: 60,
        },
        {
          title: this.$t("visitorDetailsModel.a25"),
          dataIndex: "dateTime",
          ellipsis: true,
           width: 150,
        },
        {
          title: '操作人员',
          dataIndex: "userName",
          ellipsis: true,
          width: 150,
        },
        {
          title: '操作类型',//this.$t("visitorDetailsModel.a27"),
          dataIndex: "eventMsg",
          width: 100,
          ellipsis: true,
        },
        // {
        //   title: this.$t("visitorDetailsModel.a31"),
        //   dataIndex: "eventKey",
        //   width: 200,
        //   ellipsis: true,
        // },
        {
          title: this.$t("visitorBookingRecord.a12"),
          dataIndex: "eventValue",
          width: 200,
          ellipsis: true,
        },
        {
          title: this.$t("visitorDetailsModel.a30"),
          dataIndex: "memo",
          ellipsis: true,
        },        
      ],          
    };
  },
  computed: {    
  },  
  watch:{
    // 监听表格表头显示内容生成表格表头
    visitorDetails: {
      handler(newValue) {
        console.log('newValue',newValue);
        if(newValue.orderNo){
            this.loading = false;
        }else{
            this.loading = true;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {  
    getVisitorOrderDetails(siteId,orderId){
      this.loading = true;
      getVisitorOrderInfo(siteId,orderId)
        .then((res) => {              
         
          let { data } = res;  
          let idx =1;
          data.visitors.forEach(element => {
            element.no = idx;
            idx++;
          });
          idx =1;
          data.events.forEach(element => {
            element.no = idx;
            idx++;
          });
          this.visitorDetails.events=data.events;
          this.visitorDetails.visitors=data.visitors
          this.visitorDetails.visitorStatus		   = data.visitorStatus		;
          this.visitorDetails.visitorStatusName  = data.visitorStatusName  ;
          this.visitorDetails.orderStatus        = data.orderStatus        ;
          this.visitorDetails.orderStatusName    = data.orderStatusName    ;
          this.visitorDetails.memo               = data.memo               ;
          console.log("getVisitorOrderDetails res:",data);
           this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },  
    memo(){
      let par = {
        memo:this.visitorDetails.memo,
        orderId:this.visitorDetails.orderId,
      };
      let siteId = this.$route.query.id;
      console.log("updateMemo par:",par);
      this.loading = true;
      updateMemo(siteId,par)
        .then((res) => {              
          this.loading = false;
          let { data } = res;   
          if(data && res.errorCode =='00'){
            this.$message.info(res.msg); 
            this.getVisitorOrderDetails(siteId,par.orderId);
          } else {
            this.$message.error(res.msg); 
            this.loading = false;
          }     
          console.log("updateMemo res:",res);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    confirm(record){
      let status = 2;//到访确认
      if(record.visitorStatus==2){
        status = 3; //离开确认
      }else if(record.visitorStatus==3){
        status = 4;//完成确认
      }
      let par = {
        orderId:this.visitorDetails.orderId,
        peopleId:record.id,
        visitStatus:status
      };
      let siteId = this.$route.query.id;
      console.log("confirmVisitorStatus par:",par);
      this.loading = true;
      confirmVisitorStatus(this.$route.query.id,par)
        .then((res) => {              
         this.loading = false;
          let { data } = res;   
          if(data && res.errorCode =='00'){
            this.$message.info(res.msg); 
            record.visitStatus = status;
            this.getVisitorOrderDetails(siteId,par.orderId);
          } else {
            this.$message.error(res.msg); 
            this.loading = false;
          }     
          console.log("confirmVisitorStatus res:",res);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    onCancel() {
      this.visible = false;
    },   
    handleOk(e) {
      console.log("handleOk e:",e);
      this.visible = false;
    },
    handleCancel(){
      this.visible = false;
    },
    dvHandleOk(e) {
      console.log("dvHandleOk e:",e);
      this.dvChooseVisible = false;
    },

  },
  components: {
    // "my-devcieContact":deviceContact,
  },
};
</script>

<style scoped>
#visitorDetailsModel {
  width: 100%;
  height: 100%;  
  overflow: hidden;
  
}
.main {
  width: 100%;
  height: 100%;  
  overflow: auto;
}


div /deep/.ant-form-item{
  margin-bottom: 0;
}
div /deep/.el-loading-mask {
    position: absolute;
    z-index: 2000;
    background-color: rgba(255,255,255,.9);
    margin: 0;
    top: -60px;
    right: -5px;
    bottom: -60px;
    left: -10px;
    transition: opacity .3s;
}
</style>